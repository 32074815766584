/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

export const NotificationsStyle = {
  listItem: {
    borderBottomColor: "grey.300",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    padding: "1rem",
    "&:last-of-type": { borderBottom: "none" },
    "&:hover": {
      backgroundColor: "grey.200",
    },
    width: "100%",
  },
};

export default NotificationsStyle;

import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import formElementAnnotatedStyle from "./formElementAnnotatedStyle";
import Typography from "@mui/material/Typography";
import FormElementReactHookForm, { FormElementReactHookFormProps } from "./FormElementReactHookForm";

const useStyles = makeStyles(formElementAnnotatedStyle);
export type FormElementAnnotatedProps = {
  headline: string;
  description: string;
} & FormElementReactHookFormProps;
const FormElementAnnotated = ({ headline, description, ...rest }: FormElementAnnotatedProps) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant={"h3"}>{headline}</Typography>
      <br />
      <Typography>{description}</Typography>
      <div className={classes.field}>
        <FormElementReactHookForm {...rest} />
      </div>
    </>
  );
};

export default FormElementAnnotated;

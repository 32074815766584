import React from "react";
import { EditorState, Modifier } from "draft-js";
import { MenuItem, Select } from "@mui/material";
import _ from "lodash";

type AddPlaceHolderProps = {
  editorState?: EditorState; //is mandatory but passed automatically by the editor but i dont know how to tell that to typescript
  onChange?: Function; //is mandatory but passed automatically by the editor but i dont know how to tell that to typescript
  placeholders: Record<string, string>;
  productClassName: string;
};

const AddPlaceholder = ({ editorState, onChange, placeholders, productClassName }: AddPlaceHolderProps) => {

  //can be removed if you find out how to tell typescript that its automatically passed by the editor
  if (!editorState || !onChange) {
    return null;
  }

  const addPlaceholder = (placeholder: string) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  const handlePlaceholderClick = (path: string) => {
    addPlaceholder(`<%= translate(product, "${path.substring(0, path.indexOf(".label"))}") %>`);
  };

  return (
    <Select value={"static"} variant="standard" disableUnderline={true}>
      <MenuItem value={"static"} disabled>
        Wert einfügen
      </MenuItem>
      <MenuItem onClick={() => addPlaceholder("<%= salutation(product, formValues) %>")}>Grußform</MenuItem>
      <MenuItem onClick={() => addPlaceholder('<%= translate(product, "calendlyLinkRoundRobin") %>')}>
        Terminlink nach Rechtsprodukt
      </MenuItem>
      <MenuItem onClick={() => addPlaceholder('<%= translate(product, "calendlyLinkLawyer") %>')}>
        Terminlink nach Anwalt
      </MenuItem>
      <MenuItem
        onClick={() =>
          addPlaceholder('<%= wrapInLinkTag("https://legalbird.io" + getCaseLink(product, "/extern"), "Zum Fall") %>')
        }
      >
        Link externer Fall
      </MenuItem>
      <MenuItem
        onClick={() => addPlaceholder('<%= wrapInLinkTag(getServiceWorldCaseLink(product), "Zu meinem Fall") %>')}
      >
        Link "Zu meinem Fall"
      </MenuItem>
      <MenuItem
        onClick={() =>
          addPlaceholder('<%= wrapInLinkTag(getServiceWorldCaseLink(product, "upload"), "Dokumente hochladen") %>')
        }
      >
        Dokumente hochladen pro Fall
      </MenuItem>
      <MenuItem
        onClick={() =>
          addPlaceholder('<%= wrapInLinkTag(getServiceWorldCaseLink(product, "status"), "Angaben vervollständigen") %>')
        }
      >
        Angaben vervollständigen pro Fall
      </MenuItem>
      <MenuItem
        onClick={() =>
          addPlaceholder('<%= getWeRepresentAllClients(product) %>')
        }
      >
        Vertretung rechtlicher Interessen
      </MenuItem>
      <MenuItem
        onClick={() => addPlaceholder('<%= translate(product, "genderedResponsibleLawyer") %>')}
      >
        Rechtsanwalt/Rechtsanwältin [Name]
      </MenuItem>
      {/* TODO if this gets bigger, extract into extensionfile and access via productclassname*/}
      {productClassName === "traffic" && (
        <MenuItem onClick={() => addPlaceholder('<%= translate(product, "appealRevokeLink") %>')}>
          Link Einspruchsrücknahme
        </MenuItem>
      )}
      {productClassName === "contractLaw" && (
        <MenuItem onClick={() => addPlaceholder('<%= translate(product, "furtherProcessLink") %>')}>
          Link Rückmeldung weiteres Vorgehen
        </MenuItem>
      )}
      {productClassName === "contractLaw" && (
        <MenuItem onClick={() => addPlaceholder('In dem Rechtsstreit\n<%= translate(product, "rubrumContractLaw") %>')}>
          Rubrum - Gerichtliches Verfahren
        </MenuItem>
      )}
      {_.map(placeholders, (label, path) => (
        <MenuItem key={path} value={path} onClick={() => handlePlaceholderClick(path)}>
          {label} ({path.substring(0, path.indexOf(".label"))})
        </MenuItem>
      ))}
    </Select>
  );
};

export default AddPlaceholder;

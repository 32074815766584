import React, { useState } from "react";
import { Grid } from "@mui/material";
import ValidatorSelect from "../Validator/ValidatorSelect";
import ValidatorTextField from "../Validator/ValidatorTextField";
import MenuItem from "@mui/material/MenuItem";
import { translate } from "../../services/Translations/translatorService";
import { emailDefault, requiredFieldDefault, textFieldDefault } from "../../services/validationRules";
import { formValue } from "../../services/formServiceFunctions";
import FormElementAlert from "../Case/CaseForm/FormElementAlert";
import { Roles } from "../../types/BackofficeUser";
import { UseForm } from "../../types/UseForm";

type BackofficeUserDataFormElementsProps = {
  useFormProps: UseForm;
  showExternalFields?: boolean;
  initialValues: UseForm["values"];
  roleEditingEnabled?: boolean;
};

export default function BackofficeUserDataFormElements({
  useFormProps,
  showExternalFields = false,
  initialValues,
  roleEditingEnabled = false,
}: BackofficeUserDataFormElementsProps) {
  const { values, errors, handleChange, registerValidators, handleBlur } = useFormProps;
  const [isShowAlert, setIsShowAlert] = useState(false);

  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={1} alignItems={"center"}>
        <Grid item xs={12}>
          <ValidatorSelect
            label={"Anrede"}
            validators={requiredFieldDefault}
            registerValidators={registerValidators}
            name={"gender"}
            value={formValue(values, "gender")}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors["gender"]}
            helperText={errors["gender"]}
          >
            <MenuItem value={"female"}>Frau</MenuItem>
            <MenuItem value={"male"}>Herr</MenuItem>
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12} md={6}>
          <ValidatorTextField
            name={"givenName"}
            label={"Vorname"}
            validators={[...textFieldDefault, ...requiredFieldDefault]}
            registerValidators={registerValidators}
            value={formValue(values, "givenName")}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors["givenName"]}
            helperText={errors["givenName"]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ValidatorTextField
            name={"familyName"}
            label={"Nachname"}
            validators={[...textFieldDefault, ...requiredFieldDefault]}
            registerValidators={registerValidators}
            value={formValue(values, "familyName")}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors["familyName"]}
            helperText={errors["familyName"]}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatorTextField
            name={"email"}
            label={"E-Mail-Adresse"}
            validators={[...emailDefault, ...requiredFieldDefault]}
            registerValidators={registerValidators}
            value={formValue(values, "email")}
            onChange={handleChange}
            onBlur={(event) => {
              handleBlur(event);
              setIsShowAlert(true);
            }}
            error={!!errors["email"]}
            helperText={errors["email"]}
          />
          <FormElementAlert
            alert={{
              onChange:
                "Das Ändern der E-Mail-Adresse führt dazu, dass der Benutzer sich nicht mehr mit seiner alten Adresse anmelden kann, sondern nur noch mit der neuen Adresse.",
            }}
            initialValue={formValue(initialValues, "email")}
            currentValue={formValue(values, "email")}
            path={"email"}
            setIsShowAlert={setIsShowAlert}
            isShowAlert={isShowAlert}
            handleChange={handleChange}
            label={"E-Mail-Adresse"}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelect
            label={"Rolle"}
            multiple
            disabled={!roleEditingEnabled}
            validators={requiredFieldDefault}
            registerValidators={registerValidators}
            name={"roles"}
            value={formValue(values, "roles")}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors["roles"]}
            helperText={errors["roles"]}
          >
            <MenuItem value={Roles.admin}>{translate("backofficeUser.roles.values." + Roles.admin)}</MenuItem>
            <MenuItem value={Roles.leadAccountmanager}>
              {translate("backofficeUser.roles.values." + Roles.leadAccountmanager)}
            </MenuItem>
            <MenuItem value={Roles.accountmanager}>
              {translate("backofficeUser.roles.values." + Roles.accountmanager)}
            </MenuItem>
            <MenuItem value={Roles.lawyer}>{translate("backofficeUser.roles.values." + Roles.lawyer)}</MenuItem>
            <MenuItem value={Roles.traineeLawyer}>
              {translate("backofficeUser.roles.values." + Roles.traineeLawyer)}
            </MenuItem>
            <MenuItem value={Roles.accounting}>{translate("backofficeUser.roles.values." + Roles.accounting)}</MenuItem>
            <MenuItem value={Roles.developer}>{translate("backofficeUser.roles.values." + Roles.developer)}</MenuItem>
            <MenuItem value={Roles.banking}>{translate("backofficeUser.roles.values." + Roles.banking)}</MenuItem>
            <MenuItem value={Roles.callcenter}>{translate("backofficeUser.roles.values." + Roles.callcenter)}</MenuItem>
            <MenuItem value={Roles.external} disabled>
              {translate("backofficeUser.roles.values." + Roles.external)}
            </MenuItem>
            <MenuItem value={Roles.user} disabled>
              {translate("backofficeUser.roles.values." + Roles.user)}
            </MenuItem>
          </ValidatorSelect>
        </Grid>
        {showExternalFields && (
          <Grid item xs={12}>
            <ValidatorSelect
              label={translate("externalUser.processableTaskTypes.label")}
              multiple
              validators={requiredFieldDefault}
              registerValidators={registerValidators}
              name={"processableTaskTypes"}
              value={formValue(values, "processableTaskTypes")}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors["processableTaskTypes"]}
              helperText={errors["processableTaskTypes"]}
            >
              <MenuItem value={"legalDocuments"}>
                {translate("externalUser.processableTaskTypes.values.legalDocuments")}
              </MenuItem>
              <MenuItem value={"advisories"}>
                {translate("externalUser.processableTaskTypes.values.advisories")}
              </MenuItem>
              <MenuItem value={"checkCases"}>
                {translate("externalUser.processableTaskTypes.values.checkCases")}
              </MenuItem>
              <MenuItem value={"investigationFiles"}>
                {translate("externalUser.processableTaskTypes.values.investigationFiles")}
              </MenuItem>
              <MenuItem value={"unpaidTasks"}>
                {translate("externalUser.processableTaskTypes.values.unpaidTasks")}
              </MenuItem>
            </ValidatorSelect>
          </Grid>
        )}
      </Grid>
    </>
  );
}

import personTranslations from "./personTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import { courtTranslations } from "./courtTranslations";
import { opponentLawFirmTranslations } from "./opponentLawFirmTranslations";

const litigationCaseTranslations = {
  ...basicCaseTranslations,
  advoAssistHearing: {
    label: "Advo Assist Termin",
  },
  opponentLawyer: personTranslations,
  referenceOpponentLawyer: {
    label: "Aktenzeichen Anwalt Gegenseite",
  },
  opponentLawFirm: opponentLawFirmTranslations,
  objectiveCommitted: {
    label: "Zielsetzung Gerichtsverfahren",
    values: {
      fulfillment: "Erfüllung",
      withdrawal: "Rücktritt",
      reduction: "Erstattung",
      compensation: "Schadensersatz",
      repair: "Nacherfüllung",
      replacement: "Ersatz",
    },
  },
  statementOfClaimDocument: {
    label: "Klageschrift",
  },
  judgementReceivedDate: {
    label: "Eingang Urteil am",
  },
  nextInstanceRecommendation: {
    label: "Empfehlung Kanzlei nächste Instanz",
    values: {
      appeal: "Rechtsmittel einlegen",
      noAppeal: "Keine Rechtsmittel einlegen",
    },
  },
  nextInstanceDecisionClient: {
    label: "Entscheidung Mandantschaft nächste Instanz",
    values: {
      appeal: "Berufung einlegen",
      noAppeal: "Keine Berufung einlegen",
      checkJudgement: "Empfehlung einholen",
    },
  },
  statementOfClaimSentDate: {
    label: "Klage eingereicht am",
  },
  promulgationOfJudgementDate: {
    label: "Verkündungsdatum des Urteils",
  },
  judgementEebSentDate: {
    label: "EB-Datum Urteil",
  },
  statementOfClaimFiledDate: {
    label: "Klage erhalten am",
  },
  statementOfDefenseDocument: {
    label: "Klageerwiderung eingereicht am",
  },
  statementOfDefenseSentDate: {
    label: "Klageerwiderung",
  },
  statementOfDefenseFiledDate: {
    label: "Klageerwiderung erhalten am",
  },
  replicaDocument: {
    label: "Replik",
  },
  replicaSentDate: {
    label: "Replik eingereicht am",
  },
  replicaFiledDate: {
    label: "Replik erhalten am",
  },
  customerLawsuitCompletedApprovalDate: {
    settlement: {
      label: "Zustimmung Mandant zum Vergleich am",
    },
    lawsuitRevocation: {
      label: "Zustimmung Mandant zur Klagerücknahme am",
    },
    resolution: {
      label: "Zustimmung Mandant zur Erledigungserklärung am",
    },
    //cause the validation cant access form values
    label: "Datum Zustimmung Gerichtsverfahrensende",
  },
  lawsuitCompletedReason: {
    label: "Rechtsstreit beendet durch",
    values: {
      courtDecision: "Urteil",
      settlement: "Vergleich",
      lawsuitRevocation: "Klagerücknahme",
      resolution: "Erledigung",
      defaultJudgement: "Versäumnisurteil",
    },
  },
  lawsuitCompletedDate: {
    label: "Gerichtsverfahren beendet am",
  },
  valueInDisputePreliminary: {
    label: "vorläufiger Streitwert",
  },
  valueInDispute: {
    label: "Streitwert",
  },
  hasStandardInvoicing: {
    label: "Standardabrechnung?",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  subtractExtrajudicialProcessFee: {
    label: "Anrechnung außergerichtliches Verfahren",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  netRvgAmount: {
    label: "Gesamtnettobetrag RVG Rechnung",
  },
  costBearingByOpponentFinalAbsolute: {
    label: "Gezahlte Kosten brutto durch Gegner exkl. GK",
  },
  costBearingByOpponentRelative: {
    label: "Kostenanteil Gegner laut Quotelung im Urteil in %",
  },
  courtCostsFinal: {
    label: "Gerichtskosten laut Beschluss",
  },
  principalClaim: {
    label: "Hauptforderung",
  },
  principalClaimDefaultDate: {
    label: "Hauptforderung im Verzug seit",
  },
  accessoryClaim: {
    label: "Nebenforderung",
  },
  accessoryClaimDefaultDate: {
    label: "Nebenforderung im Verzug seit",
  },
  courtPostalCode: {
    label: "PLZ Gerichtsstand",
  },
  courtReference: {
    label: "Aktenzeichen Gericht",
  },
  responsibleCourt: courtTranslations,
  clientBankData: {
    label: "Bankverbindung Mandant",
    iban: {
      label: "IBAN für Rückerstattung",
    },
  },
  caseInSuspension: {
    label: "Fall in Aussetzung",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
};

export default litigationCaseTranslations;

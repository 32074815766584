import React from "react";
import { Typography } from "@mui/material";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

export default function ImpersonateInfo() {
  const currentUser = useCurrentUser();

  return (
    <>
      {!!sessionStorage.getItem("impersonate") && (
        <Typography variant={"h4"} sx={{ color: "red", textAlign: "center" }}>
          Nutzeransicht von {currentUser.person.fullname}
        </Typography>
      )}
    </>
  );
}

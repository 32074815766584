import React, { useMemo } from "react";
import { useTechStatus } from "../../provider/TechStatusProvider";
import { Box, Stack, Typography } from "@mui/material";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const InfoBar = () => {
  const techStatus = useTechStatus();
  const currentUser = useCurrentUser();

  const showTechnicalError = useMemo(() => {
    if (!techStatus?.technicalError) {
      return false;
    }

    if (
      (currentUser.isExternal && !techStatus.technicalError.showExternal) ||
      (!currentUser.isExternal && !techStatus.technicalError.showInternal)
    ) {
      return false;
    }

    return techStatus?.technicalError && techStatus.technicalError.isActive;
  }, [techStatus?.technicalError]);

  if (!techStatus || !(showTechnicalError || techStatus.release)) {
    return null;
  }

  //@ts-ignore @sven techStatus.release seems to be a boolean, even on testing where your new code should exist.
  //thus i cant resolve this merge conflict and had to put ts ignore in until you look into it
  const showReleaseWarning = (!!techStatus.release && techStatus.release.resultMessage === "Started");

  return (
    <Box sx={{ backgroundColor: (showTechnicalError || showReleaseWarning) ? "#f8d7da" : "#fff3cd" }}>
      <Stack alignItems={"center"}>
        {showTechnicalError && (
          <Typography sx={{ margin: "1rem" }}>{techStatus.technicalError.errorMessage}</Typography>
        )}
        {showReleaseWarning && (
          <Typography sx={{ margin: "1rem" }}>
            Achtung: Es läuft gerade ein Software-Update. Es wird daher in den nächsten Minuten zu kurzen
            Unterbrechungen kommen.
          </Typography>
        )}
        {techStatus.release && (
          <Typography sx={{ margin: "1rem" }}>
            Release läuft - Legalbird.io kann in wenigen Minuten wieder benutzt werden.
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default InfoBar;

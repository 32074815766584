import React from "react";
import { InputAdornment, Toolbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import NavigationBarAccountMenu from "../../components/NavigationBar/NavigationBarAccountMenu";
import SearchBar from "../../components/Algolia/SearchBar";
import MainSearchHit from "../../components/Case/CaseEntityPicker/Hit/MainSearchHit";
import { ALGOLIA_INDEX } from "../../config/_entrypoint";
import ImpersonateInfo from "../../components/Impersonate/ImpersonateInfo";

const NavigationBar = () => {
  return (
    <>
      <AppBar position={"sticky"} color={"inherit"}>
        <Toolbar>
          <SearchBar
            HitComponent={MainSearchHit}
            resultListConfig={{
              itemSize: 270,
              height: Math.round(window.innerHeight * 0.8),
            }}
            searchConfig={{
              indexName: ALGOLIA_INDEX,
            }}
            endAdornment={<InputAdornment position="end"><NavigationBarAccountMenu /></InputAdornment>}
          />
        </Toolbar>
        <ImpersonateInfo />
      </AppBar>
    </>
  );
};

export default NavigationBar;

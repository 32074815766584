import ContentBox from "../ContentBox/ContentBox";
import { Box, Grid, IconButton, MenuItem, SelectChangeEvent, Stack, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../services/Product/ProductService";
import React, { SyntheticEvent, useEffect, useState } from "react";
import SearchBar from "../Algolia/SearchBar";
import { ALGOLIA_INDEX } from "../../config/_entrypoint";
import CaseHit from "../Case/CaseEntityPicker/Hit/CaseHit";
import { Hit } from "@algolia/client-search";
import { Delete, InfoOutlined } from "@mui/icons-material";
import useForm from "../../hooks/useForm";
import ValidatorTextField from "../Validator/ValidatorTextField";
import { convertToFloat, formValue, toIban } from "../../services/formServiceFunctions";
import { ibanFieldDefault, requiredFieldDefault } from "../../services/validationRules";
import SubmitButton from "../Button/SubmitButton";
import { apiGet, apiPost } from "../../services/Api/apiCall";
import { AbstractCase } from "../../types/AbstractCase";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import ValidatorNumberField from "../Validator/ValidatorNumberField";
import { WireTransfer } from "../../types/WireTransfer";
import InfoBox from "../InfoBox/InfoBox";
import { getWiretransferDisplayStatus } from "../../services/wiretransferService";
import moment from "moment";
import { IbanInput } from "../MaskedInputs/IbanInput";
import InputAdornment from "@mui/material/InputAdornment";
import { getLabelFromHit } from "../../services/Board/algolia";
import ValidatorSelect from "../Validator/ValidatorSelect";
import LabeledSelect from "../Select/LabeledSelect";
import LegalbirdDropzone from "../Case/CaseUploadFile/LegalbirdDropzone";
import ApiClient from "../../services/ApiClient";
import { useSnackbar } from "notistack";
import { MediaObject } from "../../types/MediaObject";
import { getMediaObjectFilters } from "../../services/mediaObjectService";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import _ from "lodash";
import { HydraResult } from "../../types/HydraResult";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import { translate } from "../../services/Translations/translatorService";
import { UseForm } from "../../types/UseForm";
import { scrollToAnchor } from "../../theme/commonStyles";

type AddWiretransferProps = {
  currentCase?: AbstractCase;
  postSubmitCallback?: Function;
};

const LBRA_ORGANIZATION_IRI = "/organizations/1";
const RS_ORGANIZATION_IRI = "/organizations/3";

export default function AddWiretransfer({ currentCase, postSubmitCallback }: AddWiretransferProps) {
  const [caseHit, setCaseHit] = useState<Hit<any> | null>(null);
  const [caseWiretransferCollection, setCaseWiretransferCollection] = useState<WireTransfer[] | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [updateKey, setUpdateKey] = useState(0);
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  let mediaObjectsFilter: Partial<AbstractCase> = {
    id: 0,
    productClassName: "divorce",
  };
  const caseHitToProxyCase = (caseHit: Hit<any>) => {
    return {
      id: caseHit.productId,
      productClassName: caseHit.product,
      insurance: {
        caseReferenceNumber: caseHit.caseReferenceNumber,
      },
      reference: caseHit.reference,
    };
  };

  const hasCorrespondingCase = !!(currentCase || caseHit);
  const correspondingCase = hasCorrespondingCase ? currentCase || caseHitToProxyCase(caseHit) : null;

  if (hasCorrespondingCase) {
    mediaObjectsFilter = getMediaObjectFilters(correspondingCase!, currentUser);
  }

  const getCaseId = () => {
    return correspondingCase?.id;
  };

  const { data: mediaObjectsCollection } = useQuery<HydraResult<MediaObject>>(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: hasCorrespondingCase,
    }
  );

  const initialValues = {
    recipientName: "",
    amount: "",
    subject: "",
    iban: "",
    payingOrganization: LBRA_ORGANIZATION_IRI,
    typeOfPayment: "",
    selectedMediaObjectId: "",
  };

  const onSubmit = async ({ values }: any) => {
    let data = { ...values };
    data.amount = convertToFloat(data.amount);
    data.createdBy = currentUser["@id"];
    data.caseId = getCaseId();
    data.iban = toIban(data.iban);

    const missingFileMessage = "Es muss ein Beleg angehangen werden";

    if (!data.caseId) {
      if (!files[0]) {
        enqueueSnackbar(missingFileMessage, {
          variant: "custom",
          isNonInteractive: true,
          persist: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        return;
      }

      let headers = new Headers();
      headers.set("Content-Type", "multipart/form-data");
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("fieldname", "wireTransferReceipt");
      let mediaObject: MediaObject;
      try {
        mediaObject = await ApiClient.post("media_objects", {
          headers: headers,
          body: formData,
        });
        data.receipt = mediaObject["@id"];
      } catch (e) {
        enqueueSnackbar("Die Datei konnte nicht hochgeladen werden", {
          variant: "custom",
          isNonInteractive: true,
          persist: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        return;
      }
    }
    if (data.caseId && mediaObjectsCollection) {
      const selectedMediaObject = mediaObjectsCollection["hydra:member"].find(
        (mediaObject) => mediaObject.id + "" === formValue(values, "selectedMediaObjectId")
      );

      data.receipt = !!selectedMediaObject && selectedMediaObject["@id"];
    }

    if (!data.receipt) {
      enqueueSnackbar("Technischer Fehler: Die Datei konnte nicht hinzugefügt werden", {
        variant: "custom",
        isNonInteractive: true,
        persist: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      return;
    }

    await apiPost("wiretransfers", data);
    setCaseHit(null);
    clearForm();
    setFiles([]);
    setUpdateKey(prevKey => prevKey + 1);
    if (postSubmitCallback) {
      postSubmitCallback();
    }
    if (!currentCase) {
      // we must not fetch the wireTransfers again here since the value of caseHit is still the old one until the next rerender,
      // so it would fetch the wireTransfers for the previously selected case
      // currentCase is set on the case accounting page, but not on the wire transfer page under tools, where caseHit is used
      return;
    }
    fetchCaseWireTransfers();
  };

  const { values, errors, handleChange, handleSubmit, registerValidators, handleBlur, clearForm, isLoading }: UseForm =
    useForm({
      initialValues,
      onSubmit,
    });

  useEffect(() => {
    setCaseWiretransferCollection(null);
    fetchCaseWireTransfers();
  }, [caseHit, currentCase]);

  const onHitClick = async (hit: Hit<any>, event: SyntheticEvent<HTMLElement> | undefined) => {
    event && event.preventDefault();
    setCaseHit(hit);
  };

  const fetchCaseWireTransfers = () => {
    if (!getCaseId()) {
      setCaseWiretransferCollection(null);
      return;
    }
    apiGet(`wiretransfers?caseId=${getCaseId()}&exists[deletedDate]=false`).then((result) =>
      setCaseWiretransferCollection(result["hydra:member"])
    );
  };

  const getSimilarExistingWiretransfer = () => {
    if (!caseWiretransferCollection || !values.amount) {
      return null;
    }

    return caseWiretransferCollection.find((wiretransfer) => wiretransfer.amount === parseFloat(values.amount)) || null;
  };

  const similarExistingWiretransfer = getSimilarExistingWiretransfer();

  const handlePrefill = ({
    payingOrganization,
    typeOfPayment,
    subject,
  }: {
    payingOrganization?: string;
    typeOfPayment?: string;
    subject?: string;
  }) => {
    if (payingOrganization) {
      handleChange({
        target: {
          name: "payingOrganization",
          value: payingOrganization,
        },
      });
    }
    if (typeOfPayment) {
      handleChange({
        target: {
          name: "typeOfPayment",
          value: typeOfPayment,
        },
      });
    }
    if (subject) {
      handleChange({
        target: {
          name: "subject",
          value: subject,
        },
      });
    }
  };

  const handleTemplateChange = (event: SelectChangeEvent) => {
    switch (event.target.value) {
      case "other":
        break;
      case "feePayment":
        handlePrefill({
          payingOrganization: LBRA_ORGANIZATION_IRI,
          typeOfPayment: "caseUnrelated",
          subject: hasCorrespondingCase ? "/" + correspondingCase!.reference : undefined,
        });
        break;
      case "thirdPartyFunds":
        handlePrefill({
          payingOrganization: LBRA_ORGANIZATION_IRI,
          typeOfPayment: "thirdPartyFunds",
          subject: hasCorrespondingCase ? "Weiterleitung Forderung von - " + correspondingCase!.reference : undefined,
        });
        break;
      case "expenses":
        handlePrefill({
          payingOrganization: RS_ORGANIZATION_IRI,
          typeOfPayment: "caseUnrelated",
          subject: "Expenses " + currentUser.person.fullname + " vom " + moment().format("DD.MM.YYYY"),
        });
        break;
      case "creditNoteCourtAdvancePayment":
        handlePrefill({
          payingOrganization: LBRA_ORGANIZATION_IRI,
          typeOfPayment: "thirdPartyFunds",
          subject: hasCorrespondingCase
            ? "Leistungs-/Schadennummer " +
              correspondingCase!.insurance.caseReferenceNumber +
              " - Rueckzahlung Gerichtskostenueberschuss"
            : undefined,
        });
        break;
      case "creditNoteSurplusFinalPayment":
        handlePrefill({
          payingOrganization: LBRA_ORGANIZATION_IRI,
          typeOfPayment: "feeRefund",
          subject: hasCorrespondingCase
            ? "Leistungs-/Schadennummer " +
              correspondingCase!.insurance.caseReferenceNumber +
              " - Rueckzahlung Ueberschuss aus Abschlussrechnung"
            : undefined,
        });
        break;
      case "nonAdvoAssistRepresentativeInvoice":
        handlePrefill({
          payingOrganization: LBRA_ORGANIZATION_IRI,
          typeOfPayment: "caseUnrelated",
          subject: hasCorrespondingCase ? "/" + correspondingCase!.reference : undefined,
        });
        break;
      default:
        break;
    }
  };

  return (
    <ContentBox headline="Neue Überweisung erstellen">
      <Box sx={scrollToAnchor} id={"createNewWiretransfer"} />
      {!currentCase && (
        <>
          <Typography variant={"h4"} sx={{ textAlign: "center", marginBottom: "1rem" }}>
            Fallzuordnung (optional)
          </Typography>
          <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"center"}>
            <Box>
              {!!caseHit?.productId ? (
                <>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={getCaseLink({ productClassName: caseHit.product, id: caseHit.productId })}
                    target={"_blank"}
                  >
                    {getLabelFromHit(caseHit) || "Zum Fall"}
                  </Link>
                  <IconButton onClick={() => setCaseHit(null)}>
                    <Delete />
                  </IconButton>
                </>
              ) : (
                "Unzugeordnet"
              )}
            </Box>
            <Box>
              <SearchBar
                searchConfig={{
                  indexName: ALGOLIA_INDEX,
                }}
                resultListConfig={{
                  itemSize: 330,
                  width: "100%",
                }}
                HitComponent={CaseHit}
                onHitClick={onHitClick}
              />
            </Box>
          </Stack>
          <Typography variant={"h4"} sx={{ textAlign: "center", margin: "1rem 0" }}>
            Überweisungsdaten
          </Typography>
        </>
      )}
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} md={4}>
          <LabeledSelect label={"Überweisungsvorlage"} onChange={handleTemplateChange}>
            {userHasOneOfTheseRoles(currentUser, [Roles.admin, Roles.accounting]) && (
              <MenuItem value={"other"}>Sonstige Überweisung</MenuItem>
            )}
            <MenuItem value={"feePayment"} disabled={!correspondingCase}>
              Gebührenbegleichung
            </MenuItem>
            <MenuItem value={"thirdPartyFunds"} disabled={!correspondingCase}>
              Fremdgeld
            </MenuItem>
            <MenuItem value={"expenses"}>Expenses</MenuItem>
            <MenuItem value={"creditNoteCourtAdvancePayment"} disabled={!correspondingCase}>
              Rückzahlung GKV an RSV
            </MenuItem>
            <MenuItem value={"creditNoteSurplusFinalPayment"} disabled={!correspondingCase}>
              Rückzahlung Überschuss aus Abschlussrechnung an RSV
            </MenuItem>
            <MenuItem value={"nonAdvoAssistRepresentativeInvoice"} disabled={!correspondingCase}>
              Begleichung TV Rechnung (abseits von AdvoAssist)
            </MenuItem>
          </LabeledSelect>
        </Grid>
        <Grid item xs={12} md={8} />
        <Grid item xs={12} md={4}>
          <ValidatorTextField
            label={"Empfänger"}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"recipientName"}
            value={formValue(values, "recipientName")}
            validators={[...requiredFieldDefault]}
            registerValidators={registerValidators}
            error={!!errors["recipientName"]}
            helperText={errors["recipientName"]}
            isHighlighted={false}
            initialDependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ValidatorTextField
            label={"IBAN"}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"iban"}
            value={formValue(values, "iban")}
            validators={[...requiredFieldDefault, ...ibanFieldDefault]}
            InputProps={{
              inputComponent: IbanInput,
              startAdornment: <InputAdornment position="start">DE</InputAdornment>,
            }}
            registerValidators={registerValidators}
            error={!!errors["iban"]}
            helperText={errors["iban"]}
            isHighlighted={false}
            initialDependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ValidatorNumberField
            fieldType={"money"}
            numberType={"float"}
            label={"Betrag"}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"amount"}
            value={formValue(values, "amount")}
            registerValidators={registerValidators}
            error={!!errors["amount"]}
            helperText={errors["amount"]}
            validators={requiredFieldDefault}
            isHighlighted={false}
            dependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={10}>
          <ValidatorTextField
            label={"Verwendungszweck"}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"subject"}
            value={formValue(values, "subject")}
            validators={[...requiredFieldDefault]}
            registerValidators={registerValidators}
            error={!!errors["subject"]}
            helperText={errors["subject"] || "Maximal 115 Zeichen"}
            inputProps={{ maxLength: 115 }}
            isHighlighted={false}
            initialDependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={2}>
          <Stack justifyContent="center" alignItems="center" sx={{ height: "85%" }}>
            <Tooltip title="Muster in Confluence" arrow>
              <IconButton
                onClick={() =>
                  window.open(
                    "https://legalbird.atlassian.net/wiki/spaces/OP/pages/3388178435/Musterverwendungszwecke+berweisungen",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <ValidatorSelect
            label={"Firmenkonto"}
            name={"payingOrganization"}
            value={formValue(values, "payingOrganization")}
            onChange={handleChange}
            onBlur={handleBlur}
            registerValidators={registerValidators}
            validators={requiredFieldDefault}
            error={!!errors["payingOrganization"]}
            helperText={errors["payingOrganization"]}
          >
            <MenuItem value={LBRA_ORGANIZATION_IRI}>LBRA</MenuItem>
            <MenuItem value={RS_ORGANIZATION_IRI}>R&S</MenuItem>
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12} md={4}>
          <ValidatorSelect
            label={translate("wireTransfer.typeOfPayment.label")}
            name={"typeOfPayment"}
            value={formValue(values, "typeOfPayment")}
            onChange={handleChange}
            onBlur={handleBlur}
            registerValidators={registerValidators}
            validators={requiredFieldDefault}
            error={!!errors["typeOfPayment"]}
            helperText={errors["typeOfPayment"]}
          >
            <MenuItem value={"standard"}>{translate("wireTransfer.typeOfPayment.values.standard")}</MenuItem>
            <MenuItem value={"feeRefund"}>{translate("wireTransfer.typeOfPayment.values.feeRefund")}</MenuItem>
            <MenuItem value={"thirdPartyFunds"}>
              {translate("wireTransfer.typeOfPayment.values.thirdPartyFunds")}
            </MenuItem>
            <MenuItem value={"caseUnrelated"}>{translate("wireTransfer.typeOfPayment.values.caseUnrelated")}</MenuItem>
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          {getCaseId() ? (
            <ValidatorSelect
              label={"Zugehöriges Dokument"}
              name={"selectedMediaObjectId"}
              value={formValue(values, "selectedMediaObjectId")}
              onChange={handleChange}
              registerValidators={registerValidators}
              validators={requiredFieldDefault}
              error={!!_.get(errors, "selectedMediaObjectId")}
              helperText={_.get(errors, "selectedMediaObjectId")}
            >
              {mediaObjectsCollection &&
                _.map(mediaObjectsCollection["hydra:member"], (mediaObject) => (
                  <MenuItem
                    key={mediaObject.id}
                    value={mediaObject.id + ""}
                    disabled={mediaObject.mimeType !== "application/pdf" || mediaObject.size > 20000000}
                  >
                    {mediaObject.description}
                  </MenuItem>
                ))}
            </ValidatorSelect>
          ) : (
            <LegalbirdDropzone files={files} setFiles={setFiles} acceptedFiles={["application/pdf"]} key={updateKey} />
          )}
        </Grid>
        {!!similarExistingWiretransfer && (
          <Grid item xs={12}>
            <InfoBox>
              Wichtiger Hinweis: Am {moment(similarExistingWiretransfer.created).format("DD.MM.YYYY")} wurde bereits
              eine Überweisung über diesen Betrag eingestellt (Status der Überweisung:{" "}
              {getWiretransferDisplayStatus(similarExistingWiretransfer)}).{" "}
              <strong>Bitte am Fall auf Doppelüberweisung prüfen.</strong> Soll diese Überweisung dennoch eingestellt
              werden?
            </InfoBox>
          </Grid>
        )}

        <Grid item xs={12}>
          <SubmitButton
            onClick={handleSubmit}
            variant={"contained"}
            isLoading={isLoading}
            disabled={currentCase && caseWiretransferCollection === null}
          >
            Speichern
          </SubmitButton>
        </Grid>
      </Grid>
    </ContentBox>
  );
}

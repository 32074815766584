const templatesStyle = {
  pageTitle: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  paper: {
    marginTop: ".5rem",
    padding: "1rem",
  },
  templateType: {
    flexGrow: "1",
  },
};

export default templatesStyle;

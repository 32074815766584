import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import CircleIcon from "../../../CircleIcon/CircleIcon";
import { HistoryLog } from "../../../../types/HistoryLog";
import moment from "moment";
import { getCreatedBy } from "../../../../services/backofficeUserService";
import { useBackofficeUser } from "../../../../provider/BackofficeUserProvider";
import { QuestionAnswerOutlined, UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { InquiryToCustomer } from "../../../../types/InquiryToCustomer";
import { translate } from "../../../../services/Translations/translatorService";

type CaseHistoryInquiryToCustomerProps = {
  historyLog: HistoryLog;
  type?: string;
};

export default function CaseHistoryInquiryToCustomer({ historyLog }: CaseHistoryInquiryToCustomerProps) {
  const { backofficeUsers } = useBackofficeUser();
  const inquiryToCustomer: InquiryToCustomer = historyLog.logData.inquiryToCustomer!;
  const logDate = moment(historyLog.logDate);
  const [isExpanded, setIsExpanded] = useState(false);

  const getInquiryTypeText = (
    inquiryType: "documents" | "appointment" | "information" | "informationAndDocuments" | "decision"
  ) => {
    switch (inquiryType) {
      case "documents":
        return "Dokumente";
      case "appointment":
        return "Termin";
      case "information":
        return "Informationen";
      case "informationAndDocuments":
        return "Informationen und Dokumente";
      case "decision":
        return "Entscheidung";
    }
  };
  const getDoneText = () => {
    if (inquiryToCustomer.inquiryType === "appointment") {
      if (inquiryToCustomer.doneBy === "system") {
        return "Angefragter Termin erfolgreich stattgefunden";
      }
      if (inquiryToCustomer.doneBy === "customer") {
        return "Abgelehnt durch Mandant";
      }
      return getCreatedBy(inquiryToCustomer.doneBy, backofficeUsers);
    }

    if (inquiryToCustomer.doneBy === "customer") {
      return "Beantwortung durch Mandant über Servicewelt";
    }
    return getCreatedBy(inquiryToCustomer.doneBy, backofficeUsers);
  };
  const textOrMinusIfEmpty = (text: string | null) => (!!text ? text : "-");
  const getInformationText = () => {
    let informationText = "";
    if (inquiryToCustomer.inquiryType === "appointment") {
      informationText += `Hintergrund für Gespräch: ${textOrMinusIfEmpty(
        inquiryToCustomer.inquiryTextAppointment
      )}\n\n`;
      informationText += `Begründung zur Ablehnung: ${textOrMinusIfEmpty(
        inquiryToCustomer.inquiryTextAppointmentCustomer
      )}`;
    }

    if (inquiryToCustomer.inquiryType === "decision") {
      let answer = translate(`historyItem.inquiry.decision.answer.${inquiryToCustomer.inquiryDecisionAnswer}.label`);

      informationText += `<p><strong>Frage:</strong> ${textOrMinusIfEmpty(
        inquiryToCustomer.inquiryDecisionQuestion
      )}</p>`;
      informationText += `<p><strong>Antwort Mandant:</strong> ${textOrMinusIfEmpty(answer)}</p>`;
    }
    if (["information", "informationAndDocuments"].includes(inquiryToCustomer.inquiryType)) {
      informationText += `Benötigte Informationen: ${textOrMinusIfEmpty(inquiryToCustomer.inquiryTextInformation)}\n\n`;
      informationText += `Antwort Mandant: ${textOrMinusIfEmpty(inquiryToCustomer.inquiryTextInformationCustomer)}\n\n`;
    }
    if (["documents", "informationAndDocuments"].includes(inquiryToCustomer.inquiryType)) {
      informationText += `Benötigte Dokumente: ${inquiryToCustomer.requestedDocuments
        .map((document: any) => document.documentName)
        .join(", ")}\n\n`;
      informationText += `Erklärung zu Dokumenten: ${textOrMinusIfEmpty(inquiryToCustomer.inquiryTextDocuments)}\n\n`;
      informationText += `Anmerkungen und Fragen Mandant: ${textOrMinusIfEmpty(
        inquiryToCustomer.inquiryTextDocumentsCustomer
      )}\n`;
    }
    return informationText;
  };
  return (
    <>
      <Grid container>
        <Grid item xs={2} md={1}>
          <CircleIcon icon={<QuestionAnswerOutlined />} type={"muiIcon"} />
        </Grid>
        <Grid item xs={8} md={10}>
          <Box
            sx={{
              fontSize: "1rem",
              textDecoration: "line-through",
            }}
          >
            Anfrage für {getInquiryTypeText(inquiryToCustomer.inquiryType)} gestellt{" "}
            {moment(inquiryToCustomer.created).format("DD.MM.YYYY HH:mm")} |{" "}
            {getCreatedBy(inquiryToCustomer.createdBy, backofficeUsers)}
          </Box>
          <Grid item>
            Erledigt: {logDate.format("DD.MM.YYYY HH:mm")} | {getDoneText()}
          </Grid>
          {isExpanded && (
            <Box sx={{ fontSize: "1rem", paddingTop: 1 }} dangerouslySetInnerHTML={{ __html: getInformationText() }} />
          )}
        </Grid>
        <Grid item xs={2} md={1}>
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <UnfoldLess /> : <UnfoldMore />}
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

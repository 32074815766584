import React, { useEffect, useMemo, useState } from "react";
import { Badge, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { CheckCircle, NotificationsOutlined, OpenInNew } from "@mui/icons-material";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../services/mercure";
import _ from "lodash";
import NavigationBarNotificationItem from "./NavigationBarNotificationItem/NavigationBarNotificationItem";
import { Notification } from "../../types/Notification";
import { apiGet } from "../../services/Api/apiCall";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import moment from "moment";
import { BackofficeUser } from "../../types/BackofficeUser";
import { Link } from "react-router-dom";

export default function NavigationBarNotifications() {
  const [notifications, setNotifications] = useState<any>(null);
  const currentUser = useCurrentUser();
  const cookieFetched = isCookieFetched();

  useEffect(() => {
    if (cookieFetched) {
      const eventSource = mercureSubscribe(currentUser["@id"] + "/notifications", setNotifications);
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched]);

  useEffect(() => {
    if (!notifications) {
      let options = { headers: new Headers() };
      options.headers.set("Accept", "application/json");
      apiGet(
        "notifications",
        null,
        { backofficeUser: currentUser["@id"], "created[after]": moment().subtract(30, "days").format("YYYY-MM-DD") },
        options
      ).then((notificationsResult: Notification[]) => {
        setNotifications(notificationsResult);
      });
    }
  }, [notifications]);

  return <NotificationsInner notifications={notifications} currentUser={currentUser} />;
}

const NotificationsInner = ({
  notifications,
  currentUser,
}: {
  notifications: Notification[] | null;
  currentUser: BackofficeUser;
}) => {
  const [notificationsOpen, setNotficationsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const handleClick = ({ target }: any) => {
    setAnchorEl(target);
    setNotficationsOpen(true);
  };

  const handleAllRead = () => {
    apiGet("/notifications/read");
    setNotficationsOpen(false);
  };

  const newNotificationCount = useMemo(() => {
    if (!notifications) {
      return null;
    }
    return _.filter(notifications, (notification) => !notification.isRead).length;
  }, [notifications]);

  return (
    <>
      <IconButton onClick={handleClick} size={"large"}>
        <Badge badgeContent={newNotificationCount} color={"primary"}>
          <NotificationsOutlined sx={{ height: "30px", width: "auto" }} />
        </Badge>
      </IconButton>
      {!!notifications && notifications.length > 0 ? (
        <Menu
          open={notificationsOpen}
          onClose={() => setNotficationsOpen(false)}
          anchorEl={anchorEl}
          disableScrollLock={true}
          slotProps={{
            paper: {
              sx: { width: "520px" },
            },
          }}
          MenuListProps={{
            sx: { padding: 0 },
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ padding: "0.5rem 1rem" }}
          >
            <Grid item>
              <Typography variant="h4">
                <strong>Benachrichtigungen</strong>
              </Typography>
            </Grid>
            <Grid item>
              {!!newNotificationCount && (
                <IconButton onClick={handleAllRead} size={"small"} title={"Alle als gelesen markieren"}>
                  <CheckCircle />
                </IconButton>
              )}
              {!currentUser.isExternal && (
                <IconButton
                  component={Link}
                  to={"/services/notifications"}
                  target={"blank"}
                  size={"small"}
                  title={"Benachrichtigungsseite"}
                >
                  <OpenInNew />
                </IconButton>
              )}
            </Grid>
          </Grid>
          {_.map(notifications, (notification) => (
            <MenuItem
              sx={{
                borderBottomColor: "grey.300",
                borderBottomStyle: "solid",
                borderBottomWidth: 1,
                "&:last-of-type": { borderBottom: "none" },
              }}
              key={notification.id}
            >
              <NavigationBarNotificationItem
                notification={notification}
                handleItemClick={() => setNotficationsOpen(false)}
              />
            </MenuItem>
          ))}
        </Menu>
      ) : (
        <Menu
          open={notificationsOpen}
          onClose={() => setNotficationsOpen(false)}
          anchorEl={anchorEl}
          disableScrollLock={true}
        >
          <MenuItem onClick={() => setNotficationsOpen(false)} sx={{ textAlign: "center" }}>
            Keine aktuellen Benachrichtigungen
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

import _ from "lodash";
import { AbstractCase } from "../types/AbstractCase";
import { Activity } from "../types/Activity";

export const activitiesFilterString = (product: AbstractCase, filter: Partial<Activity<string>>) => {
  const urlFilterParams = new URLSearchParams(
    // @ts-ignore
    _.merge({ case: product?.backofficeCase ? product.backofficeCase["@id"] : 0 }, filter)
  );
  return urlFilterParams.toString();
};

import React from "react";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import uploadImage from "../../assets/icon/icon_upload.svg";
import bookingImage from "../../assets/icon/icon_booking.svg";
import { List } from "@mui/material";
import { BackofficeUser, Roles } from "../../types/BackofficeUser";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import wiretransferImage from "../../assets/icon/icon_wiretransfer.svg";

type PaymentsProps = {
  currentUser: BackofficeUser;
};

const Payments = ({ currentUser }: PaymentsProps) => {
  return (
    <List>
      <LinkListItemWithIcon
        title={"Überweisungen"}
        description={"z.B. Fremdgeldzahlungen oder Gerichtskostenüberschüsse auskehren"}
        to={"/services/tools/ueberweisung"}
        imageIcon={wiretransferImage}
      />
      {userHasOneOfTheseRoles(currentUser, [Roles.admin, Roles.banking, Roles.developer]) && (
        <LinkListItemWithIcon
          title={"Hochladen"}
          description={"Aus Commerzbank exportierte Zahlungen importieren"}
          to={"/services/tools/zahlungen-hochladen"}
          imageIcon={uploadImage}
        />
      )}
      {userHasOneOfTheseRoles(currentUser, [
        Roles.admin,
        Roles.accountmanager,
        Roles.developer,
      ]) && (
        <LinkListItemWithIcon
          title={"Zahlungen buchen"}
          description={"Eingehende und Ausgehende Zahlungen manuell auf Mandantenkonten buchen"}
          to={"/services/tools/zahlungseingaenge-buchen"}
          imageIcon={bookingImage}
        />
      )}
    </List>
  );
};

export default Payments;

import React, { useEffect } from "react";
import { convertFromRaw, EditorState } from "draft-js";
import moment from "moment";
import ApiClient from "../../../services/ApiClient";
import _ from "lodash";
import { editorStateToHTML } from "../../../services/Editor/editorFunctions";
import { useSnackbar } from "notistack";
import { getCaseLink } from "../../../services/Product/ProductService";
import { buildAddress } from "./casePdfGeneratorService";
import { clearSessionStorageState, loadSessionStorageState } from "../../../services/browserStorageService";
import SubmitButton from "../../Button/SubmitButton";
import makeStyles from "@mui/styles/makeStyles";
import casePdfGeneratorStyle from "./casePdfGeneratorStyle";
import PdfGeneratorFormElements from "./PdfGeneratorFormElements";
import PdfGeneratorPreview from "./PdfGeneratorPreview";
import AddTextBlock from "../../Templates/AddTextBlock";
import ContentBox from "../../ContentBox/ContentBox";
import { Box, MenuItem } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { getMediaObjectFilters } from "../../../services/mediaObjectService";
import { FormContainer, useForm } from "react-hook-form-mui";
import { notNoneSelectValidatorDefault } from "../../../services/reactHookFormValidationRules";
import SelectElement from "../../ReactHookFormElements/SelectElement";
import { scrollToAnchor } from "../../../theme/commonStyles";
import { AbstractCase } from "../../../types/AbstractCase";
import { HydraResult } from "../../../types/HydraResult";
import { MediaObject } from "../../../types/MediaObject";

// @ts-ignore
const useStyles = makeStyles(casePdfGeneratorStyle);

type CasePdfGeneratorProps = {
  product: AbstractCase;
  documentTypeLabel?: string;
  ctaLabel?: string;
};

const CasePdfGenerator = ({
  product,
  documentTypeLabel = "Individuelles",
  ctaLabel = "Schreiben generieren",
}: CasePdfGeneratorProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const classes = useStyles();

  const mediaObjectsFilter = getMediaObjectFilters(product, currentUser);

  const { data: mediaObjects }: { data: HydraResult<MediaObject> | undefined } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product,
    }
  );

  const getParams = () => {
    const values = formContext.getValues();
    return _.merge({}, values, {
      content: editorStateToHTML(values.content),
      address: buildAddress(values, product),
      externalReference: !_.isEmpty(values.externalReference) ? values.externalReference : null,
      senderMedium: values.senderMedium === "none" ? null : values.senderMedium,
      externalLawyer: currentUser.isExternal ? currentUser : null,
      insurancePolicyNumber:
        values.recipientType === "legalExpenseInsurance" ? product.insurance.insurancePolicyNumber : null,
      caseReferenceNumber:
        values.recipientType === "legalExpenseInsurance" ? product.insurance.caseReferenceNumber : null,
    });
  };

  const onSubmit = async () => {
    await ApiClient.post("files/generate/" + product.productClassName + "/" + product.id, {
      body: JSON.stringify(_.merge({}, getParams(), { documentIdentifier: "customPdf" })),
    });

    enqueueSnackbar(
      currentUser.isExternal
        ? "Schriftsatz wurde erfolgreich erstellt und am Fall angehängt"
        : "Schreiben wurde erfolgreich erstellt",
      {
        variant: "custom",
        buttonType: "link",
        buttonLink: getCaseLink(product, currentUser.isExternal ? "/extern" : ""),
        buttonTarget: "_self",
        buttonText: "Zum Fall",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 10000,
      }
    );

    formContext.reset(initialValues);

    clearSessionStorageState("customPdf");
  };

  const initialValues = {
    recipientType: "custom",
    address: {
      name: "",
      department: "",
      subDepartment: "",
      streetAddress: "",
      postalCode: "",
      addressLocality: "",
    },
    senderMedium: "none",
    externalReference: "",
    externalReferenceSelect: "free",
    filename: moment().format("YYYYMMDD") + "_Schreiben",
    content: EditorState.createEmpty(),
    showInGallery: "true",
    sendEmailToCustomer: "false",
    selectedMediaObjects: [],
    electronicFileFolderPath: "none",
  };

  useEffect(() => {
    const sessionStorageValues = loadSessionStorageState("customPdf");
    if (sessionStorageValues) {
      formContext.reset({
        recipientType: sessionStorageValues.recipientType,
        address: {
          name: sessionStorageValues.address.name,
          department: sessionStorageValues.address.department,
          subDepartment: sessionStorageValues.address.subDepartment,
          streetAddress: sessionStorageValues.address.streetAddress,
          postalCode: sessionStorageValues.address.postalCode,
          addressLocality: sessionStorageValues.address.addressLocality,
        },
        senderMedium: sessionStorageValues.senderMedium,
        externalReference: sessionStorageValues.externalReference,
        externalReferenceSelect: sessionStorageValues.externalReferenceSelect,
        filename: sessionStorageValues.filename,
        content: EditorState.createWithContent(convertFromRaw(sessionStorageValues.content)),
        showInGallery: sessionStorageValues.showInGallery,
        sendEmailToCustomer: sessionStorageValues.sendEmailToCustomer,
        selectedMediaObjects: sessionStorageValues.selectedMediaObjects,
        electronicFileFolderPath: sessionStorageValues.electronicFileFolderPath,
      });
    }
  }, []);

  const formContext = useForm({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  return (
    <>
      <Box sx={scrollToAnchor} id={"casePdfGenerator"} />
      <ContentBox
        headline={!currentUser.isExternal ? "Individueller Schriftsatz" : documentTypeLabel + " Schreiben erstellen"}
      >
        <FormContainer formContext={formContext}>
          <PdfGeneratorFormElements
            product={product}
            mediaObjects={mediaObjects?.["hydra:member"] || []}
            editorToolBarCustomButtons={[
              <AddTextBlock
                product={product}
                formValues={formContext.getValues()}
              />,
            ]}
          />
          <PdfGeneratorPreview
            product={product}
            values={formContext.getValues()}
            getParams={getParams}
            mediaObjects={mediaObjects?.["hydra:member"] || []}
          />
          <Box sx={{ width: 200, margin: "0 auto" }}>
            <SelectElement
              label={"Ablage in Ordner"}
              name={"electronicFileFolderPath"}
              validation={{ validate: notNoneSelectValidatorDefault }}
            >
              <MenuItem value={"none"} disabled>
                {" "}
              </MenuItem>
              <MenuItem value={"Außergerichtliches Verfahren"}>Außergerichtliches Verfahren</MenuItem>
              <MenuItem value={"Gerichtliches Verfahren"}>Gerichtliches Verfahren</MenuItem>
              <MenuItem value={"Mandantschaft"}>Mandantschaft</MenuItem>
              <MenuItem value={"RSV"}>RSV</MenuItem>
              <MenuItem value={"ToDo"}>ToDo</MenuItem>
              <MenuItem value={"Sonstiges"}>Sonstiges</MenuItem>
            </SelectElement>
          </Box>
          <SubmitButton
            className={classes.submitButton}
            error={_.keys(formContext.formState.errors).length > 0}
            fullWidth={false}
            disabled={!formContext.watch("content").getCurrentContent().hasText()}
            isLoading={formContext.formState.isSubmitting}
            variant={"contained"}
            onClick={formContext.handleSubmit(onSubmit)}
          >
            {ctaLabel}
          </SubmitButton>
        </FormContainer>
      </ContentBox>
    </>
  );
};

export default CasePdfGenerator;

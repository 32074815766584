import React from "react";
import { translate } from "../../../services/Translations/translatorService";
import _ from "lodash";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import NumberFieldElement, { NumberFieldElementProps } from "../../ReactHookFormElements/NumberFieldElement";
import { ControllerProps } from "react-hook-form";
import { AbstractCase } from "../../../types/AbstractCase";
// @ts-ignore
// The recommended direct import path is incorrect because the hook-form package includes a package.json
// file that defines valid exports. TypeScript isn't recognizing these exports correctly and is throwing an error.
import { DatePickerElement } from "react-hook-form-mui/date-pickers";

export type FormElementReactHookFormProps = {
  type: "TextField" | "NumberField" | "DateField";
  path: string;
  label?: string;
  isDisabled?: Function;
  isLoading?: boolean;
  validators?: ControllerProps["rules"];
  isHidden?: Function;
  additionalProps?: Record<string, any>;
  product: AbstractCase;
  translationPath?: string;
  fieldType?: string;
  numberType?: string;
} & Pick<NumberFieldElementProps, "fieldType" | "numberType">;

const FormElementReactHookForm = ({
  type,
  path,
  label,
  isDisabled = () => false,
  isLoading = false,
  validators,
  isHidden = () => false,
  additionalProps,
  product,
  translationPath,
  fieldType,
  numberType,
}: FormElementReactHookFormProps) => {
  const formContext = useFormContext();
  const values = formContext.getValues();
  if (isHidden({ values, product })) {
    return null;
  }

  if (_.isFunction(translationPath)) {
    translationPath = translationPath({ values });
  }

  const internalTranslationPath = translationPath || path;

  switch (type) {
    case "TextField":
      return (
        <TextFieldElement
          label={translate(`${internalTranslationPath}.label`, label)}
          name={path}
          disabled={isLoading || isDisabled({ values, product })}
          autoComplete={"none"}
          rules={validators}
          {...additionalProps}
        />
      );
    case "NumberField":
      return (
        <NumberFieldElement
          name={path}
          label={translate(`${internalTranslationPath}.label`, label)}
          disabled={isLoading || isDisabled({ values, product })}
          autoComplete={"none"}
          rules={validators}
          fieldType={fieldType}
          numberType={numberType}
          {...additionalProps}
        />
      );
    case "DateField":
      return (
        <DatePickerElement
          name={path}
          label={translate(`${internalTranslationPath}.label`, label)}
          disabled={isLoading || isDisabled({ values, product })}
          rules={validators}
        />
      );
    default:
      return null;
  }
};

export default FormElementReactHookForm;

import React from "react";
import AppBar from "@mui/material/AppBar";
import { Box, Stack, Toolbar } from "@mui/material";
import BirdIcon from "../../assets/icon/bird-icon.svg";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import NavigationBarAccountMenu from "./NavigationBarAccountMenu";
import InfoBar from "../TechStatus/InfoBar";
import { ALGOLIA_INDEX } from "../../config/_entrypoint";
import NavigationBarNotifications from "./NavigationBarNotifications";
import { userHasRole } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import { Hit } from "@algolia/client-search";
import SearchBar from "../Algolia/SearchBar";
import MainSearchHit from "../Case/CaseEntityPicker/Hit/MainSearchHit";
import navigationBarStyle from "./navigationBarStyle";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import ImpersonateInfo from "../Impersonate/ImpersonateInfo";

const useStyles = makeStyles(navigationBarStyle);

const NavigationBar = ({
  postSearchFilter = (hits: Array<Hit<any>>) => hits,
}: {
  postSearchFilter?: (hits: Array<Hit<any>>) => Array<Hit<any>>;
}) => {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  return (
    <AppBar position={"sticky"} color={"inherit"} className={"mui-fixed"} style={{ boxSizing: "content-box" }}>
      <InfoBar />
      <Toolbar className={classes.toolbar}>
        <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"center"}>
          <Box component={Link} to={"/"}>
            <img className={classes.navBarIcon} src={BirdIcon} alt={"Legalbird"} />
          </Box>
          <Box flexGrow={1}>
            <Box margin={"auto"} minWidth={"300px"} maxWidth={"800px"}>
              <SearchBar
                HitComponent={MainSearchHit}
                resultListConfig={{
                  itemSize: 270,
                  height: Math.round(window.innerHeight * 0.8),
                }}
                searchConfig={{
                  indexName: ALGOLIA_INDEX,
                  postSearchFilter: postSearchFilter,
                }}
              />
            </Box>
          </Box>
          <Box>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              {!userHasRole(currentUser, Roles.callcenter) && (
                <Box>
                  <NavigationBarNotifications />
                </Box>
              )}
              <Box>
                <NavigationBarAccountMenu />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Toolbar>
      <ImpersonateInfo />
    </AppBar>
  );
};
export default NavigationBar;

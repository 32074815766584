import {
  PAID_TASK_STATUS_APPROVED,
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_REQUESTED,
  PAID_TASK_STATUS_IN_REVIEW,
  PAID_TASK_STATUS_PAID,
} from "../../components/PaidTask/paidTaskStatus";

const paidTaskTranslations = {
  type: {
    letter: "Außergerichtliches Schreiben",
    statementOfClaim: "Klageschrift",
    counterplea: "Replik auf Klageerwiderung",
    rejoinder: "Duplik",
    surrejoinder: "Triplik",
    rebuttal: "Quadruplik",
    statementOfDefense: "Klageerwiderung des Beklagten",
    contractCheck: "Vertragsprüfung",
    fullMandate: "Vollmandat",
    custom: "Individuelle Aufgabe",
    dataLeakCounterplea: "Schriftsatz Gerichtsverfahren Illegale Datenweitergabe",
    utilityBillReview:  "Nebenkostenprüfung",
  },
  status: {
    [PAID_TASK_STATUS_CREATED]: "Nicht zugewiesen",
    [PAID_TASK_STATUS_REQUESTED]: "Angefragt",
    [PAID_TASK_STATUS_ASSIGNED]: "In Bearbeitung",
    [PAID_TASK_STATUS_IN_REVIEW]: "In Prüfung",
    [PAID_TASK_STATUS_APPROVED]: "Abgenommen",
    [PAID_TASK_STATUS_PAID]: "Bezahlt",
  },
  subject: "Name der Aufgabe",
  description: "Beschreibung",
  definitionOfDone: "Abnahmekriterien",
  reviewRemarks: "Änderungswünsche",
  assignmentDeadline: "Anzunehmen bis",
  doneDeadline: "Zu erledigen bis",
  fee: "Honorar",
};

export default paidTaskTranslations;

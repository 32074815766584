import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";

const subscriptionContractTranslations = {
  customer: customerTranslations,
  ...basicCaseTranslations,
  ...contractClaimTranslations,
  label: "Abonnement",
  reasonForDispute: {
    label: "Problem des Kunden",
    values: {
      subscriptionTrap: "Abofalle",
      serviceUnavailable: "Service nicht verfügbar",
      cancellationRefused: "Kündigung nicht akzeptiert",
      other: "Sonstiges",
    },
  },
  wasDeceived: {
    label: "Täuschung bei Abschluss",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  salesChannel: {
    label: "Wo abgeschlossen",
    values: {
      online: "Im Internet",
      phone: "Per Telefon",
      other: "Sonstiges",
    },
  },
  subscriptionName: {
    label: "Name des Abonnements",
  },
  endOfContractDate: {
    label: "Datum Vertragsende",
  },
  contractNumber: {
    label: "Vertragsnummer",
  },
};

export default subscriptionContractTranslations;

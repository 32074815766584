import React from "react";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import divorceCostImage from "../../assets/icon/icon_divorce_costs.svg";
import { List } from "@mui/material";
import birdImage from "../../assets/icon/bird-icon.svg";
import bookingImage from "../../assets/icon/icon_booking.svg";
import authorityImage from "../../assets/icon/icon_authority.svg";

const Controlling = () => {
  return (
    <List>
      <LinkListItemWithIcon
        title={"Zahlläufe erstellen"}
        description={"Zahlläufe für Advoassist, Ausschreibungen und aus CSV Dateien"}
        to={"/services/tools/zahllauf"}
        imageIcon={divorceCostImage}
      />
      <LinkListItemWithIcon
        title={"Monatscontrolling LBRA UG"}
        description={"Tools für Monatsabschluss für Datev und Softwarekosten"}
        to={"/services/tools/controlling"}
        imageIcon={birdImage}
      />
      <LinkListItemWithIcon
        title={"Externe Gutschriften"}
        description={"CSV hochladen, erstellte Gutschriften versenden & Zahllauf"}
        to={"/services/tools/externe-rechnungen-erstellen"}
        imageIcon={bookingImage}
      />
      <LinkListItemWithIcon
        title={"Rechnungen Ermittlungsakten"}
        description={"Aus Rechnungen automatisch Rechnungsdaten auslesen"}
        to={"/services/tools/bearbeitung-rechnungen-ermittlungsakten"}
        imageIcon={authorityImage}
      />
    </List>
  );
};

export default Controlling;

const templateRowStyles = {
  actions: {
    width: "7rem",
    marginLeft: "auto",
  },
  deleteText: {
    textAlign: "center",
    marginTop: "2rem",
  },
  icon: {
    color: "black",
  },
  selectedFont: {
      color: "#137f7b",
  },
};

export default templateRowStyles;

import React, { useCallback, useEffect, useState } from "react";
import templatesStyle from "./templatesStyle";
import Grid from "@mui/material/Grid";
import { MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PageContentContainer from "../Container/PageContentContainer";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import TemplateRow from "./TemplateRow";
import CreateNewTemplate from "./CreateNewTemplate";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import ButtonLoading from "../Button/ButtonLoading";
import ValidatorSelect from "../Validator/ValidatorSelect";
import PageHeadline from "../PageHeadline/PageHeadline";
import { translate } from "../../services/Translations/translatorService";
import Template from "../../types/Template";

const Templates = () => {
  const [pickedProduct, setPickedProduct] = useState("general");
  const [templatesType, setTemplatesType] = useState("email");
  const [templates, setTemplates] = useState<Template[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTemplates = useCallback(async () => {
    const result = await ApiClient.get("templates");
    setTemplates(result["hydra:member"]);
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  useEffect(() => {
    setFilteredTemplates(
      _.filter(templates, (template) => template.productClassName === pickedProduct && template.type === templatesType)
    );
  }, [pickedProduct, templates, templatesType]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination || source.index === destination.index) {
      return;
    }
    let reorderedTemplates = _.merge([], filteredTemplates);
    reorderedTemplates.splice(source.index, 1);
    reorderedTemplates.splice(destination.index, 0, filteredTemplates[source.index]);
    setFilteredTemplates(reorderedTemplates);
  };

  const saveOrder = async () => {
    setIsLoading(true);
    let promiseList: Promise<Template>[] = [];
    _.forEach(filteredTemplates, (template, index) => {
      promiseList.push(ApiClient.put("templates/" + template.id, { body: JSON.stringify({ orderId: index }) }));
    });
    await Promise.all(promiseList);
    setIsLoading(false);
  };

  return (
    <PageContentContainer size={"small"}>
      <PageHeadline main="Vorlagen verwalten" />
      <Grid container justifyContent={"flex-end"} alignItems={"center"} spacing={3}>
        <Grid item sx={templatesStyle.templateType}>
          <ValidatorSelect
            label="Vorlage"
            value={templatesType}
            onChange={(event) => setTemplatesType(event.target.value)}
          >
            <MenuItem value={"email"}>E-Mail-Vorlagen</MenuItem>
            <MenuItem value={"text"}>Textvorlagen</MenuItem>
          </ValidatorSelect>
        </Grid>
        <Grid item>
          <CreateNewTemplate refreshTemplateList={fetchTemplates} initialProduct={pickedProduct} type={templatesType} />
        </Grid>
        <Grid item>
          <ButtonLoading isLoading={isLoading} onClick={saveOrder}>
            Sortierung speichern
          </ButtonLoading>
        </Grid>
        <Grid item xs={3}>
          <ValidatorSelect
            label="Rechtsprodukt"
            value={pickedProduct}
            onChange={(event) => setPickedProduct(event.target.value)}
            fullWidth
          >
            <MenuItem value={"divorce"}>Scheidung</MenuItem>
            <MenuItem value={"settlement"}>Abfindung</MenuItem>
            <MenuItem value={"traffic"}>Verkehr</MenuItem>
            <MenuItem value={"death"}>Tod und Erbe</MenuItem>
            <MenuItem value={"alimony"}>Unterhalt</MenuItem>
            <MenuItem value={"patientDecree"}>Patientenverfügung</MenuItem>
            <MenuItem value={"powerOfAttorney"}>Vorsorgevollmacht</MenuItem>
            <MenuItem value={"familyLawCase"}>Familienrecht</MenuItem>
            <MenuItem value={"contractLaw"}>Vertragsrecht</MenuItem>
            <MenuItem value={"dataLeakContract"}>Datenweitergabe</MenuItem>
            <MenuItem value={"rentalContract"}>{translate("rentalContract.label")}</MenuItem>
            <MenuItem value={"general"}>Produktübergreifend</MenuItem>
          </ValidatorSelect>
        </Grid>
      </Grid>
      <Paper sx={templatesStyle.paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Vorlage</TableCell>
              <TableCell>{templatesType === "email" && "Betreff"}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"1"}>
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {_.map(filteredTemplates, (template, index) => (
                    <TemplateRow
                      index={index}
                      key={template.id}
                      template={template}
                      refreshTemplateList={fetchTemplates}
                    />
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </Paper>
    </PageContentContainer>
  );
};

export default Templates;

import React from "react";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import { PersonAddOutlined } from "@mui/icons-material";
import tasksImage from "../../assets/icon/icon_progress.svg";
import pcsImage from "../../assets/icon/icon_vkh.svg";
import trafficFineImage from "../../assets/icon/icon_traffic_fine.svg";
import divorceCostImage from "../../assets/icon/icon_divorce_costs.svg";
import { List } from "@mui/material";

const ClientsAndCases = () => {
  return (
    <List>
      <LinkListItemWithIcon
        title={"Neuer Fall"}
        description={"Neuen Mandant oder Fall anlegen"}
        to={"/services/tools/mandant-anlegen"}
        MuiIcon={PersonAddOutlined}
      />
      <LinkListItemWithIcon
        title={"Ausschreibungen"}
        description={"Ausgeschriebene Aufgaben verwalten"}
        to={"/services/ausgeschriebene-aufgaben/verwalten"}
        imageIcon={tasksImage}
      />
      <LinkListItemWithIcon
        title={"VKH Anträge"}
        description={"VKH-Anträge prüfen und freigeben"}
        to={"/services/tools/verfahrenskostenhilfe"}
        imageIcon={pcsImage}
      />
      <LinkListItemWithIcon
        title={"Bußgeldrechner"}
        description={"Berechnen von Bußgeld und Fahrverbot"}
        to={"/services/tools/bussgeldrechner"}
        imageIcon={trafficFineImage}
      />
      <LinkListItemWithIcon
        title={"Scheidungskosten-Rechner"}
        description={"Durch Einkommen und Renten Kosten berechnen"}
        to={"/services/tools/scheidungskostenrechner"}
        imageIcon={divorceCostImage}
      />
    </List>
  );
};

export default ClientsAndCases;

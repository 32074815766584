import React from "react";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import { List } from "@mui/material";
import checkMediaObjectsImage from "../../assets/icon/icon_documents_check.svg";
import beaSearchIcon from "../../assets/icon/bea_search.svg";
import beaAttachmentFilterIcon from "../../assets/icon/filter_listpx.svg";
import beaListIcon from "../../assets/icon/Tools_beA.svg";

const Mail = () => {
  return (
    <List>
      <LinkListItemWithIcon
        title={"Dokumente prüfen"}
        description={"Dokumente prüfen, freigeben und zuordnen"}
        to={"/services/tools/dokumente-pruefen"}
        imageIcon={checkMediaObjectsImage}
      />
      <LinkListItemWithIcon
        title={"beA-Anlagen"}
        description={"Sichten und Zusammenfügen von Schriftsätzen und Anhängen"}
        to={"/services/tools/bea"}
        imageIcon={beaListIcon}
      />
      <LinkListItemWithIcon
        title={"beA-Adressen"}
        description={"Empfänger in beA suchen"}
        to={"/services/tools/bea/suche"}
        imageIcon={beaSearchIcon}
      />
      <LinkListItemWithIcon
        title={"beA-Anhangsfilter"}
        description={"Nicht benötigte Anhänge aussortieren"}
        to={"/services/tools/bea/anhangs-filter"}
        imageIcon={beaAttachmentFilterIcon}
      />
    </List>
  );
};

export default Mail;
